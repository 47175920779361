import { MembershipAvailability } from "@/db/types";
import {
  TimeDifference,
  convertCamelCaseToCapitalize,
  convertSnakeCaseToNoCaseCapitalize,
  dateDiff,
  grayColor,
  greenColor,
  redColor,
  yellowColor,
} from "@/utils";

export type TransformedWorkspaceMembershipAvailabilityRecord = {
  color: string;
  lastOnline?: string;
  drivingStatus?: string;
  dutyStatus?: string;
  availability: string;
  offline: boolean;
  status: string;
  toolTip: string;
  additionalInfo?: string[];
};

export type ExtraStatusType = {
  value: string;
  active: boolean;
  statusType: boolean;
};

export const transformWorkspaceMembershipAvailabilityRecord = (
  wsMaR: MembershipAvailability,
): TransformedWorkspaceMembershipAvailabilityRecord => {
  /**
   * HOS [Hours of service]: 🚚
   */
  if (!wsMaR || Object?.keys(wsMaR)?.length === 0) {
    return;
  }

  const offlineThreshold: number = 10; // 10 minutes
  const handsFreeEnabled: boolean =
    wsMaR.mostRecentHandsFreeStatusEnabled === true;
  const muted: boolean = !(wsMaR.mostRecentVolumeAudible ?? true);
  const availability: string | null = wsMaR.calculatedAvailability;
  const dutyStatus: string | null = wsMaR.mostRecentDutyStatusStatus;
  const drivingStatus: string | null = wsMaR.mostRecentDrivingStatus
    ? wsMaR.mostRecentDutyStatusStatus === "active"
      ? "Driving"
      : "Not Driving"
    : null;

  // Determine the date diff from right now
  const checkDateDiff: TimeDifference = dateDiff(
    wsMaR.updatedAt,
    offlineThreshold,
  );

  console.log("MEMBERSHIP AVAILABILITY", {
    muted,
    handsFreeEnabled,
    availability,
    dutyStatus,
    drivingStatus,
    wsMaR,
  });

  // If the date diff is longer than the offline threshold, the user is offline
  const offline: boolean = checkDateDiff?.diffInMinutes >= offlineThreshold;

  // Last online text determined from date diff
  const lastOnline: string =
    checkDateDiff?.lastOnline?.length > 0 ? ` ${checkDateDiff.lastOnline}` : "";

  // Determine the color of the status based on the availability
  let color: string = "";
  if (offline) {
    color = grayColor;
  } else {
    switch (availability) {
      case "present":
        color = greenColor;
        break;
      case "unknown":
        color = yellowColor;
        break;
      case "notPresent":
        color = redColor;
        break;
      default:
        color = grayColor;
    }
  }

  // Status text that displays in the header and the tooltip
  let combinedStatus = "";
  if (offline) {
    combinedStatus = "Offline";
  } else {
    switch (availability) {
      case "present":
        combinedStatus = "Driving";
        break;
      case "unknown":
        combinedStatus = dutyStatus ?? drivingStatus;
        // if the availability is unknown, And dutyStatus is not present, And drivingStatus is not present set as Not Driving
        if (combinedStatus === null) {
          combinedStatus = "Not Driving";
        }
        break;
      case "notPresent":
        combinedStatus = dutyStatus ?? drivingStatus;
        break;
      default:
        combinedStatus = availability;
    }
  }

  // Change the casing of values like offDuty to Off Duty
  const combinedStatusFixedCasing =
    combinedStatus?.length > 0
      ? convertCamelCaseToCapitalize(
          convertSnakeCaseToNoCaseCapitalize(combinedStatus),
        )
      : "";

  const extras: ExtraStatusType[] = [
    {
      value: combinedStatusFixedCasing,
      active: combinedStatusFixedCasing?.length > 0,
      statusType: true,
    },
    {
      value: lastOnline,
      active: lastOnline?.length > 0,
      statusType: true,
    },
    {
      value: "Hands-Free Mode Off",
      active: !handsFreeEnabled,
      statusType: false,
    },
    {
      value: "Device Muted",
      active: muted,
      statusType: false,
    },
  ].filter((extra) => extra.active);

  // Combine all extra attributes into a single tooltip string
  const toolTip: string = extras?.map((extra) => extra.value)?.join(" · ");

  // Array of extras: Muted | Hands-Free Mode Off
  const additionalInfo: string[] = extras
    ?.filter((aI) => !aI.statusType)
    ?.map((aI) => aI.value);

  return {
    color,
    lastOnline,
    dutyStatus,
    drivingStatus,
    availability,
    offline,
    toolTip,
    additionalInfo,
    status: `${combinedStatusFixedCasing}${lastOnline}`,
  };
};

import { Status } from "@/components/FeedItemStatus";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import { Box, useTheme } from "@mui/material";
import React from "react";

export default function FeedAliasChannelStatus({
  statuses,
  aliasChannelOwnerId,
  onClick,
}: { statuses: Status[]; aliasChannelOwnerId: string; onClick: () => void }) {
  const theme = useTheme();
  const aliasOwnerStatuses = statuses.filter(
    (item) => item.account.id === aliasChannelOwnerId,
  );
  const statusRead = aliasOwnerStatuses?.find((item) => item?.read?.id);
  const statusPlayedUnknown = aliasOwnerStatuses?.find(
    (item) => item?.playedUnknown?.id && !item?.playedWhileDriving?.id,
  );
  const statusPlayedWhileDriving = aliasOwnerStatuses?.find(
    (item) => item?.playedWhileDriving?.id,
  );

  const wrapper = (icon: React.JSX.Element, text: string, color: string) => {
    return (
      <Box
        component="button"
        onClick={onClick}
        aria-label="View message details"
        sx={{
          border: "none",
          color: theme.palette.text.primary,
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
          mb: "0.25rem",
          mr: "0.25rem",
          position: "relative",
          background: "transparent",
          padding: "2px 5px 2px 2px",
          borderRadius: "0.25rem",
          "&:hover": {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: "0.25rem",
            padding: "0.125rem",
            borderRadius: "0.25rem",
            background: color,
          }}
        >
          {icon}
        </Box>
        <Box>{text}</Box>
      </Box>
    );
  };

  return (
    <Box sx={{ fontSize: "14px" }}>
      {statusPlayedWhileDriving &&
        wrapper(
          <HearingIcon sx={{ fontSize: "17px" }} />,
          "Message heard by driver",
          theme.palette.brand.other.green.light,
        )}

      {statusPlayedUnknown &&
        wrapper(
          <HearingDisabledIcon sx={{ fontSize: "17px" }} />,
          "Message played but driver may not be present",
          theme.palette.warning.main,
        )}

      {statusRead &&
        wrapper(
          <MarkChatReadIcon sx={{ fontSize: "17px" }} />,
          "Message seen by driver",
          theme.palette.brand.other.green.light,
        )}
    </Box>
  );
}

import AvatarWithStatus from "@/components/AvatarWithStatus";
import DisplayTranscript from "@/components/DisplayTranscript";
import FeedAliasChannelStatus from "@/components/FeedAliasChannelStatus";
import FileFragment from "@/components/FileFragments";
import HideShowTranscriptButton from "@/components/HideShowTranscriptButton";
import LinkFragment from "@/components/LinkFragment";
import RichTranscript from "@/components/RichTranscript";
import { client } from "@/config";
import { AccountEvent, Feed } from "@/db/types";
import ModalForm from "@/elements/ModalForm";
import useOnScreen from "@/hooks/useOnScreen";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { FeedContext, FullItem } from "@/models/FeedContextProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TelemetryContext, actions } from "@/models/TelemetryProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { deleteItem } from "@/models/actions/clientActions";
import { AllPreferredLanguage } from "@/models/languages";
import {
  defaultFocusStyles,
  menuItemFocusSX,
  randomString,
  selectAudio,
  selectTranscription,
  targetCodecForBrowser,
} from "@/utils";
import * as Icons from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import equal from "fast-deep-equal/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLongPress } from "react-aria";
import { useNavigate } from "react-router-dom";
import { throttle } from "throttle-typescript";
import { VadResult } from "web-client/api/data-contracts";
import AudioPlayer from "./AudioPlayer";
import { ContentOverlay, LongPressOverlay } from "./AudioPlayer/styles";
import FeedItemStatus, { Status } from "./FeedItemStatus";
import Loading from "./Loading/Index";
import PlaybackIndicator from "./PlaybackIndicator/Index";

type Props = {
  item: FullItem;
  feed: Feed;
  vListIndex?: number;
  active?: boolean;
  highlightPodcast: boolean;
  setItemNotLoaded?: (value: string) => void;
  preventScroll?: (value: boolean) => void;
  aliasChannelOwnerId?: string;
};

const FeedItem = ({
  item,
  feed,
  active,
  highlightPodcast,
  setItemNotLoaded,
  preventScroll,
  aliasChannelOwnerId,
}: Props) => {
  const navigate = useNavigate();
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { flags } = useContext(AppContext);
  const { preferredLanguage } = useContext(DataContext);
  const { accountEvent } = useContext(ActionContext);
  const { isCurrentFeedAdmin, haveWritePermission } =
    useContext(CurrentFeedContext);
  const { myAccount } = useContext(MyAccountContext);
  const { playerPlaying, activeItemId } = useContext(AudioAppContext);
  const [copiedTranscript, setCopiedTranscript] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const { isSmUp, userReadOnlyMode } = useContext(UxContext);
  const { ampli } = useContext(TrackingContext);
  const { itemTranscriptState, setItemTranscriptState, vListRef } =
    useContext(FeedContext);
  const { accountMap } = useContext(WorkspaceContext);
  const feedStatusesRef = useRef<any>();
  const theme = useTheme();
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { vadThreshold } = useFlags();

  // if zero then disable VAD
  const vadEnabled = !(
    vadThreshold === undefined ||
    vadThreshold === null ||
    vadThreshold === 0
  );
  const speechPercentage = vadEnabled ? vadThreshold : 0;

  const mine = item.accountId === myAccount?.id;

  const unread = item.unread;

  const transcriptions = item.transcriptions;
  const audioContents = item.audioEncodings;

  const rawFiles = item.files;
  const rawLinks = item.links;
  const displayArtifactRecord = item.displayArtifact;
  const pam = item.pam;

  const senderAccount = item.account;

  const startedEvent = "Started Listening to Feed Item";
  const completedEvent = "Finished Listening to Feed Item";
  const seenEvent = "Saw Feed Item";

  const allEvents = useMemo(
    () => item.events?.filter((e) => item.accountId !== e.accountId),
    [item.events, item.accountId],
  );

  const setStatusData = (status: Status, statusEvent: AccountEvent) => {
    // check to see if the event contains the work "listen"
    const listenEvent = statusEvent?.name?.toLowerCase().includes("listen");

    // only set the status if the event is for the alias channel owner
    if (aliasChannelOwnerId === status.account.id) {
      if (statusEvent?.memberAvailability === "present" && listenEvent) {
        status.playedWhileDriving = statusEvent;
      }
      if (statusEvent?.memberAvailability === "unknown" && listenEvent) {
        status.playedUnknown = statusEvent;
      }
      if (statusEvent.name === seenEvent) {
        status.read = statusEvent;
      }
    } else {
      if (statusEvent.name === startedEvent) {
        status.started = statusEvent;
      } else if (statusEvent.name === completedEvent) {
        status.completed = statusEvent;
      } else if (statusEvent.name === seenEvent) {
        status.read = statusEvent;
      }
    }
    return status;
  };

  const statuses = useMemo(() => {
    const itemAccountStatus = new Map<string, Status>();
    if (!isProcessing && allEvents) {
      for (const statusEvent of allEvents) {
        const eventAccount = accountMap.get(statusEvent.accountId);

        if (!eventAccount) continue;

        let status: Status = {
          account: eventAccount,
          started: null,
          completed: null,
          read: null,
          playedWhileDriving: null,
          playedUnknown: null,
        };
        if (itemAccountStatus.has(eventAccount?.id)) {
          status = itemAccountStatus.get(eventAccount?.id);
        }
        status = setStatusData(status, statusEvent);
        itemAccountStatus.set(eventAccount?.id, status);
      }
      return Array.from(itemAccountStatus.values());
    }
    return [];
  }, [isProcessing, allEvents, accountMap]);

  const files =
    rawFiles?.length > 0
      ? rawFiles.map((file) => ({
          ...file,
          fragment: FileFragment(file),
        }))
      : [];

  const links =
    rawLinks?.length > 0
      ? rawLinks.map((link) => ({
          ...link,
          fragment: LinkFragment(link),
        }))
      : [];

  const messageGridStyles = () => ({
    gridTemplateAreas: {
      xs: `"avatar info info info info"
"avatar message message message message"
"avatar details details details details"`,
      sm:
        mine || isCurrentFeedAdmin
          ? `"avatar info info info ."
"avatar message message message message"
"avatar details details details ."`
          : `"avatar info info info ."
"avatar message message message ."
"avatar details details details ."`,
    },
    gridTemplateColumns: "40px 1fr 1fr 1fr 34px",
  });

  const { simpleHtml, richTranscript, textTranscript } = selectTranscription(
    preferredLanguage,
    transcriptions,
  );
  const transcriptionContent = simpleHtml || richTranscript || textTranscript;
  const inputLanguage = transcriptions?.filter((t) => !t.translatedFrom)[0]
    ?.language as AllPreferredLanguage;

  const targetCodec = targetCodecForBrowser();

  const [showModalTranscription, setShowModalTranscription] =
    useState<boolean>(false);

  const suitableAudioContent = useMemo(
    () =>
      selectAudio(
        preferredLanguage,
        audioContents?.filter((a) => a.codec === targetCodec),
        inputLanguage,
      ),
    [audioContents, inputLanguage, preferredLanguage, targetCodec],
  );

  const originalAudio = audioContents?.find((a) => a?.translatedFrom === null);
  const originalAudioVadResultJson = pam?.find(
    (p) => p?.contentId === originalAudio?.contentId,
  )?.vadResult;
  const originalAudioVadResult = originalAudioVadResultJson
    ? (JSON.parse(originalAudioVadResultJson) as VadResult)
    : null;
  const isTTSMessage = originalAudio?.generatedVoice ? true : false;

  const audioContent = suitableAudioContent[0];

  let duration: number = Number(audioContent?.duration) || 0;
  if (!duration) {
    // console.log("Calculating duration from other codec");
    duration = Number(
      audioContents?.filter(
        (a) =>
          a.codec !== targetCodec &&
          a.duration &&
          a.language === audioContent?.language,
      )[0]?.duration,
    );
  }

  const hasAudio = audioContents?.length >= 2;
  const hasPlayableAudio = suitableAudioContent.length > 0;
  const hasTranscription = !!transcriptionContent || transcriptions?.length > 0;
  const noTranscriptionContent =
    (hasTranscription && !transcriptionContent) || transcriptions?.length === 0;
  const hasFiles = rawFiles?.length > 0;
  const hasLinks = rawLinks?.length > 0;
  const filesOrLinks = hasFiles || hasLinks;

  useEffect(() => {
    if (filesOrLinks || hasPlayableAudio || hasTranscription) {
      setIsProcessing(false);
    } else {
      setIsProcessing(true);
    }
  }, [filesOrLinks, hasPlayableAudio, hasTranscription]);

  useEffect(() => {
    const attributes = {
      fromAppsync: item.fromAppsync,
      hasPlayableAudio,
      hasTranscription,
      itemId: item.id,
      feedId: item.feedId,
      senderId: item.accountId,
    };
    if (!hasPlayableAudio) {
      trackAction(actions.feedItemAudioLoading(item), attributes);
    } else {
      finishAction(actions.feedItemAudioLoading(item));
      finishAction(actions.feedItemAudioTts(item));
    }
    if (!hasTranscription) {
      trackAction(actions.feedItemTextLoading(item), attributes);
    } else {
      finishAction(actions.feedItemTextLoading(item));
      finishAction(actions.feedItemTextTts(item));
      finishAction(actions.bootstrapFull());
      finishAction(actions.feedNavigate(item.feedId));
    }
  }, [hasPlayableAudio, hasTranscription]);

  const showTranscription = itemTranscriptState?.includes(item?.id);

  const unreliableTranscription =
    isTTSMessage ||
    !vadEnabled ||
    typeof originalAudioVadResult?.speechPercentage !== "number"
      ? false
      : originalAudioVadResult?.speechPercentage < speechPercentage;

  const unreliableTranscriptLabel = `This transcription ${
    noTranscriptionContent ? "is not available." : "might be unreliable."
  }`;

  const paperColor = () => {
    if (displayArtifactRecord) {
      return theme.palette.brand.other.teal.main;
    } else {
      return mine
        ? theme.palette.brand.primary.dark
        : theme.palette.neutral.dark;
    }
  };
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  /**
   * @NOTE keyboard v. mouse "open" states
   *  - when a menu opens via keyboard, its first item is focused
   *  - however, its control element should also appear to be focused but is not
   *  - we're currently "hacking" this into place, which requires knowing which gesture was taken
   *
   * @TODO consolidate duplicate logic across components into one that handles this internally
   */
  const [anchorElOpen, setAnchorElOpen] = useState(false);
  const open = Boolean(anchorEl);

  const { longPressProps } = useLongPress({
    isDisabled: isSmUp || false,
    accessibilityDescription: "Long press to edit message",
    onLongPress: (event) => {
      setAnchorEl(event.target);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    ampli.viewMessageContextMenu();
  };

  const _handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const keyCode = event.key.toLowerCase();

    /**
     * @NOTE filter key presses
     *  - when initially focused, this event will fire off of `TAB`
     *  -> in order to keep the illusion that the parent element is opened,
     *     we need to throttle + filter for <space> or <enter>
     */
    if (keyCode === " " || keyCode === "enter") {
      setAnchorElOpen(!anchorElOpen);
    }
  };

  const handleKeyUp = throttle(_handleKeyUp, 100);

  const handleMessageMenuClose = () => {
    setAnchorEl(null);
    setAnchorElOpen(false);
  };

  const makeID = (len = 5) =>
    `feedItem-${feed?.id}-${item?.id}-${randomString(len)}`;

  const [feedItemId] = useState(makeID());

  const handleDelete = async () => {
    try {
      preventScroll(true);
      setDeleting(true);
      ampli.deleteRecord({ itemId: item.id });
      setAnchorEl(null);
      await deleteItem(item.id);
      setConfirmDeleteDialog(false);
    } catch (e) {
      console.log(e);
    }
    setDeleting(false);
  };

  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref, {
    root: document.querySelector("#vListRef"),
    threshold: 1,
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    if (
      !isProcessing &&
      isVisible &&
      accountEvent &&
      !item.hasRegisteredSeenEvent
    ) {
      accountEvent(seenEvent, {
        feedId: item.feedId,
        feedItemId: item.id,
      });
    }
  }, [isVisible, isProcessing, item?.id, item?.feedId, userReadOnlyMode]);

  const accountName = useMemo(() => {
    if (isProcessing) {
      return null;
    }
    if (!senderAccount) {
      return `Deactivated user: ${item.accountId}`;
    }
    return !mine ? senderAccount?.name : "Me";
  }, [mine, isProcessing, senderAccount?.id]);
  const itemDate = item?.createdAt
    ? format(new Date(item.createdAt), "MMM d, yyyy, h:mm aa")
    : "";

  const itemStyles = {
    boxSizing: "border-box",
    background: paperColor,
    borderRadius: "16px",
    border: () => {
      let color = mine
        ? theme.palette.brand.primary.light
        : theme.palette.secondary.light;
      if (displayArtifactRecord) {
        color = theme.palette.brand.other.teal.dark;
      }
      return `1px solid ${color}`;
    },
  };

  const handlePlay = useCallback(
    async (time: number) => {
      accountEvent(startedEvent, {
        feedId: feed.id,
        feedItemId: item.id,
        data: { time },
      });
    },
    [feed.id, item.id],
  );

  const handleFinished = useCallback(async () => {
    accountEvent(completedEvent, {
      feedId: feed.id,
      feedItemId: item.id,
    });
  }, [accountEvent, feed.id, item]);

  const handlePause = useCallback(
    async (time: number) => {
      accountEvent("Paused Feed Item", {
        feedId: feed.id,
        feedItemId: item.id,
        data: { time },
      });
    },
    [accountEvent, feed.id, item],
  );

  const handleCopy = (value, setter) => {
    setter(() => true);
    navigator.clipboard.writeText(value);
    setTimeout(() => setter(() => false), 3000);
  };

  const handleStatusOpen = useCallback(() => {
    if (feedStatusesRef.current) {
      feedStatusesRef.current?.open();
      handleMessageMenuClose();
    }
  }, [handleMessageMenuClose]);

  const refreshPipeline = async () => {
    setIsProcessing(true);
    try {
      await client.refreshContent(item.contentId);
      setTimeout(() => setIsProcessing(false), 3000);
    } catch (error) {
      setIsProcessing(false);
      console.error("Error refreshing content:", error);
    }
  };

  const MenuOptions = {
    showOptions: isSmUp,
    showMessageDetails: (mine || isCurrentFeedAdmin) && !isProcessing,
    canDelete: (mine || isCurrentFeedAdmin) && haveWritePermission,
    canCopy: item?.url,
    canCopyTranscript: (mine || isCurrentFeedAdmin) && textTranscript,
  };

  if (
    !window ||
    (!audioContent &&
      !transcriptionContent &&
      !files &&
      !links &&
      item.status !== "Processing")
  )
    return null;

  const highlightShowMorePodcastLabel =
    displayArtifactRecord && (highlightPodcast || active);

  const messageWrapperLabel = `${accountName} at ${itemDate}, click to play`;

  return (
    <Stack
      id={item.id}
      ref={ref}
      direction={"row"}
      sx={{
        alignItems: "center",
        justifyContent: mine ? "flex-end" : "flex-start",
        width: "100%",
        height: "auto",
        minHeight: 180,
        borderRadius: "16px",
        py: 0.5,
        transition: "background 0.3s linear",
        background: active
          ? theme.palette.brand.primary.darkHighlight
          : "transparent",
      }}
      useFlexGap={true}
      spacing={2}
      aria-label={Locator.feed.items.item}
    >
      <ModalForm
        id="confirm-delete-message"
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
        disableClose={deleting}
      >
        <Box>
          <Typography sx={{ fontSize: 24 }}>Delete Message</Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            Are you sure you want to delete this message from the feed?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setConfirmDeleteDialog(false)}
              disabled={deleting}
              aria-label={Locator.feed.items.confirmMessageDeleteModal.cancel}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={deleting}
              variant="contained"
              color="error"
              sx={{ borderRadius: 6 }}
              onClick={handleDelete}
              aria-label={Locator.feed.items.confirmMessageDeleteModal.confirm}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </ModalForm>

      <Box
        sx={{
          display: "grid",
          ...messageGridStyles(),
          alignItems: "center",
          gap: 1,
          width: "100%",
          maxWidth: "541px",
          px: 1,
          alignSelf: "flex-end",
        }}
        role="presentation"
      >
        <Box sx={{ alignSelf: "flex-start", gridArea: "avatar", py: 3 }}>
          {!mine && activeItemId !== item.id && (
            <>
              {displayArtifactRecord ? (
                <Avatar
                  sx={{
                    fontSize: "20px",
                    width: 40,
                    height: 40,
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                    background: theme.palette.brand.other.teal.light,
                    border: `1.5px solid ${theme.palette.brand.other.teal.dark}`,
                  }}
                >
                  <Icons.CampaignOutlined />
                </Avatar>
              ) : (
                <AvatarWithStatus
                  accountId={item.account?.id}
                  key={item.account?.id}
                />
              )}
            </>
          )}
          {activeItemId === item.id && (
            <PlaybackIndicator playing={playerPlaying} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: mine ? "flex-end" : "flex-start",
            gridArea: "info",
            gap: 1,
          }}
          color={theme.palette.secondary.light}
          role="presentation"
        >
          <Typography
            id={`${feedItemId}-user`}
            aria-label={messageWrapperLabel}
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {item?.isSilent ? (
              <Icons.VolumeOffOutlined fontSize="small" sx={{ mr: 0.25 }} />
            ) : null}
            {accountName ? `${accountName} • ` : null}
            {itemDate}
          </Typography>
          {unread ? (
            <Box
              sx={{
                height: 8,
                width: 8,
                background: theme.palette.brand.secondary.main,
                borderRadius: "100%",
              }}
            />
          ) : null}
        </Box>
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: mine ? "flex-end" : "flex-start",
            gridArea: "message",
          }}
          direction={"row"}
          role="presentation"
        >
          <Paper
            variant="outlined"
            id={`${feedItemId}-wrapper`}
            sx={{
              ...itemStyles,
              width: "100%",
              maxWidth: isProcessing ? "120px" : "100%",
              position: "relative",
            }}
          >
            <Box sx={{ width: "100%", p: "14px" }}>
              {isProcessing ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Click to load content">
                    <Button onClick={() => setItemNotLoaded(item.id)}>
                      <Loading variant="white" size="large" baseSize={6} />
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                <Stack direction={"column"} spacing={1} width="100%">
                  {!filesOrLinks && (
                    <LongPressOverlay>
                      <AudioPlayer
                        duration={duration}
                        id={item.id}
                        containerWidth={"100%"}
                        onPlay={handlePlay}
                        onFinished={handleFinished}
                        onPause={handlePause}
                        inline
                        paperize={false}
                        disabledPlayButton={!hasPlayableAudio}
                      />
                    </LongPressOverlay>
                  )}
                  <ContentOverlay
                    {...(!isSmUp ? longPressProps : null)}
                    $hasAudio={!filesOrLinks}
                  >
                    {displayArtifactRecord && (
                      <>
                        <Typography
                          component="h3"
                          sx={{
                            fontWeight: 700,
                            fontSize: "18px",
                            wordBreak: "break-word",
                          }}
                        >
                          {displayArtifactRecord?.title}
                        </Typography>
                        <div
                          id={`${feedItemId}-description`}
                          role="textbox"
                          style={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: displayArtifactRecord?.description,
                          }}
                        />
                        <ModalForm
                          id={`transcription-${feedItemId}`}
                          open={showModalTranscription}
                          onClose={() => {
                            setShowModalTranscription(() => false);
                          }}
                          maxWidth={"80%"}
                          sx={{
                            overflow: "hidden",
                          }}
                          keepMounted
                        >
                          <Stack sx={{ height: "100%" }}>
                            <Box
                              sx={{
                                py: 5,
                                pt: { xs: 5, sm: 0 },
                              }}
                            >
                              {hasPlayableAudio && (
                                <AudioPlayer
                                  duration={duration}
                                  id={item.id}
                                  containerWidth={"100%"}
                                  onPlay={handlePlay}
                                  onFinished={handleFinished}
                                  onPause={handlePause}
                                  inline
                                  paperize={false}
                                  disabledPlayButton={!hasPlayableAudio}
                                />
                              )}
                            </Box>
                            <Box sx={{ px: "5%", overflow: "auto" }}>
                              <RichTranscript
                                preferredLanguage={preferredLanguage}
                                transcriptions={transcriptions}
                              />
                            </Box>
                          </Stack>
                        </ModalForm>
                      </>
                    )}

                    {hasTranscription && displayArtifactRecord && (
                      <HideShowTranscriptButton
                        mine={mine}
                        displayArtifact={!!displayArtifactRecord?.id}
                        showTranscription={showModalTranscription}
                        onClick={() => setShowModalTranscription(true)}
                      />
                    )}

                    {hasTranscription && !displayArtifactRecord && (
                      <DisplayTranscript
                        mine={mine}
                        feedId={item.feedId}
                        itemId={item.id}
                        showTranscription={showTranscription}
                        preferredLanguage={preferredLanguage}
                        transcriptions={transcriptions}
                        displayArtifact={!!displayArtifactRecord?.id}
                        feedItemId={feedItemId}
                        transcriptionContent={transcriptionContent}
                        unreliableTranscription={unreliableTranscription}
                        unreliableTranscriptLabel={unreliableTranscriptLabel}
                        files={files}
                        links={links}
                      />
                    )}

                    {links?.length > 0 && (
                      <List>
                        {links.map((link, index) => (
                          <ListItem
                            key={`links-${link?.id}`}
                            divider={index !== links?.length - 1}
                          >
                            {link?.fragment}
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {files && files?.length > 0 && (
                      <List>
                        {files.map((file) => (
                          <ListItem
                            onClick={() => ampli.openFile()}
                            key={`files-${file?.id}`}
                          >
                            {file?.fragment}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </ContentOverlay>
                </Stack>
              )}
            </Box>
          </Paper>
          {MenuOptions.showOptions && (
            <Box sx={{ p: "8px" }}>
              <IconButton
                color="secondary"
                aria-label={Locator.feed.items.actions.main}
                onClick={handleClick}
                onKeyUp={handleKeyUp}
                sx={{
                  ...(anchorElOpen ? defaultFocusStyles : {}),
                  height: "100%",
                  px: 0,
                  py: 1,
                  color: theme.palette.primary.main,
                }}
              >
                <Icons.MoreVert role="img" />
              </IconButton>
            </Box>
          )}

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              mt: 1,
              ...menuItemFocusSX,
            }}
            open={open}
            onClose={handleMessageMenuClose}
            slotProps={{
              paper: {
                "aria-label": Locator.feed.items.actions.menu,
              },
            }}
          >
            {MenuOptions.showMessageDetails ? (
              <MenuItem onClick={handleStatusOpen}>
                <ListItemIcon>
                  <Icons.InfoOutlined role="img" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                  Message Details
                </ListItemText>
              </MenuItem>
            ) : null}
            {MenuOptions.canCopy ? (
              <MenuItem
                onClick={() => handleCopy(item?.url, setCopiedLink)}
                aria-label={Locator.feed.items.actions.copyLink}
              >
                <ListItemIcon>
                  {copiedLink ? (
                    <Icons.Done role="presentation" />
                  ) : (
                    <Icons.Link role="presentation" />
                  )}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                  {copiedLink ? "Copied!" : "Copy Link"}
                </ListItemText>
              </MenuItem>
            ) : null}

            {MenuOptions.canCopyTranscript ? (
              <MenuItem
                onClick={() =>
                  handleCopy(
                    textTranscript?.transcriptionContent,
                    setCopiedTranscript,
                  )
                }
              >
                <ListItemIcon>
                  {copiedTranscript ? (
                    <Icons.Done role="presentation" />
                  ) : (
                    <Icons.ContentCopy role="presentation" />
                  )}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                  {copiedTranscript ? "Copied!" : "Copy transcript"}
                </ListItemText>
              </MenuItem>
            ) : null}
            {MenuOptions.canDelete && !userReadOnlyMode && (
              <MenuItem
                onClick={() => {
                  setConfirmDeleteDialog(true);
                  handleMessageMenuClose();
                }}
                aria-label={Locator.feed.items.actions.delete}
              >
                <ListItemIcon>
                  <Icons.Delete role="img" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                  Delete Message
                </ListItemText>
              </MenuItem>
            )}
          </Menu>
        </Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gridArea: "details",
          }}
        >
          <Box
            sx={{
              order: mine ? 0 : 1,
            }}
          >
            <FeedItemStatus
              aliasChannelOwnerId={
                aliasChannelOwnerId &&
                myAccount?.id !== aliasChannelOwnerId &&
                aliasChannelOwnerId !== item.account.id &&
                item.account.id !== aliasChannelOwnerId &&
                aliasChannelOwnerId
              }
              ref={feedStatusesRef}
              sender={senderAccount}
              item={item}
              statuses={statuses}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {flags.debugMode && (
              <>
                <IconButton
                  color="secondary"
                  size="small"
                  aria-label="Click to re-run pipeline for this FeedItem"
                  disabled={isProcessing}
                  onClick={refreshPipeline}
                >
                  <Icons.RefreshOutlined role="img" />
                </IconButton>
                <IconButton
                  color="secondary"
                  size="small"
                  aria-label="Click for debug info"
                  onClick={() => {
                    navigate(
                      `/workspaces/${feed?.workspaceId}/feeds/${feed?.id}/items/${item.id}/debug`,
                    );
                  }}
                >
                  <Icons.InfoOutlined role="img" />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

const checkItemChanges = (prevProps, nextProps) => {
  return (
    equal(nextProps?.item?.id, prevProps?.item?.id) &&
    equal(nextProps?.item?.active, prevProps?.item?.unread) &&
    equal(nextProps?.item?.unread, prevProps?.item?.active)
  );
};

export default memo(FeedItem, checkItemChanges);

import NoValidWorkspacesModal from "@/components/NoValidWorkspacesModal";
import RootLayout from "@/routes/layout";
import { Button, Stack, useTheme } from "@mui/material";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

export default function ErrorFallback({ error }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  let message: JSX.Element = <p>Something went wrong</p>;

  if (error) {
    switch (error.status) {
      case 401:
        message = (
          <p>
            Oops! Looks like you tried to visit a feed or page that you do not
            have access to.
          </p>
        );
        break;
      case 404:
        message = (
          <p>
            Oops! Looks like you tried to visit a feed or page that does not
            exist.
          </p>
        );
        break;
      case 418:
        message = (
          <p>
            We are currently performing system maintenance. Please check back in
            a few minutes.
          </p>
        );
        break;

      default:
        message = <p>{error?.data?.message || "Something went wrong"}</p>;
    }
  }

  const noWorkspaceToBootStrap = error
    ?.toString()
    ?.includes("No workspace to bootstrap");
  const noValidWorkspaceFound = error
    ?.toString()
    ?.includes("No valid workspace found");

  /**
   * If no workspaces are available show the user the Invalid Workspace Modal
   */
  if (noWorkspaceToBootStrap || noValidWorkspaceFound) {
    return (
      <RootLayout>
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "100dvh",
            px: 2,
            backgroundColor: theme.palette.primary.dark,
            backgroundImage:
              "url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
            backgroundPosition: "50%",
            backgroundSize: "cover",
          }}
          role="alert"
        >
          <NoValidWorkspacesModal
            open={true}
            debug="errorFallback: noWorkspaceToBootStrap || noValidWorkspaceFound"
          />
        </Stack>
      </RootLayout>
    );
  }

  return (
    <RootLayout>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
          px: 2,
          backgroundColor: theme.palette.primary.dark,
          backgroundImage:
            "url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
          backgroundPosition: "50%",
          backgroundSize: "cover",
        }}
        role="alert"
      >
        {error ? <h1>Error {error?.status}</h1> : <h1>Something went wrong</h1>}
        {message}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ maxWidth: "max-content" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ maxWidth: "max-content" }}
            onClick={() => {
              resetBoundary();
            }}
          >
            Try Again
          </Button>
        </Stack>
      </Stack>
    </RootLayout>
  );
}

import Loading from "@/components/Loading/Index";
import StoryboardChat from "@/components/StoryboardChat";
import { client } from "@/config";
import ModalForm from "@/elements/ModalForm";
import UseFirebase from "@/hooks/firebase/useFirebase";
import UseStorage from "@/hooks/useStorage";
import Locator from "@/locator";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { Box, Button, Typography } from "@mui/material";
import * as Sentry from "@sentry/browser";
import React, { useContext, useRef, useState } from "react";

export default function NoValidWorkspacesModal({
  open,
  debug,
}: { open: boolean; debug?: string }) {
  const { ampli } = useContext(TrackingContext);
  const { clearLocalStorage } = UseStorage();
  const { deleteActiveToken } = UseFirebase();
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const logoutString = client?.logoutLink() ?? "/";
  const activeError = useRef<boolean>(false);

  const clearSession = async () => {
    ampli.noWorkspaceViewLogout();
    setLoggingOut(() => true);
    await deleteActiveToken();
    clearLocalStorage();
  };

  if (debug && !activeError.current) {
    activeError.current = true;
    console.info("No Valid Workspace Modal Error");
    console.error(debug);
    ampli.noWorkspaceView({ extra: { debugInfo: debug } });
    Sentry.captureException(
      `No Valid Workspace Modal Error: ${JSON.stringify(debug)}`,
    );
  }

  return (
    <>
      <ModalForm
        onClose={() => {}}
        open={loggingOut}
        allowClose={false}
        sx={{ background: "transparent" }}
      >
        <>
          <Box>
            <StoryboardChat />
          </Box>
          <Box>
            <Loading size="large" />
          </Box>
        </>
      </ModalForm>
      <ModalForm
        onClose={() => {}}
        id="no-valid-workspaces-modal"
        open={open && !loggingOut}
        allowClose={false}
        sx={{ padding: "40px 60px" }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            You are not a member of any workspaces
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Contact your system administrator to invite you to your company’s
            workspace, or{" "}
            <a
              onClick={clearSession}
              style={{ color: "#26B0EB", cursor: "pointer" }}
              href={logoutString}
            >
              log out
            </a>{" "}
            and sign in with a different credential.
          </Typography>
          <Button
            href="https://storyboard.com/speak-with-a-product-specialist?utm_campaign=FY23%20%7C%20In-app%20-%20Hands%20Free%20Upsell&utm_source=In-app-upsell&utm_medium=new-workspace"
            variant="contained"
            aria-label={Locator.blankSlate.requestDemo.button}
          >
            Request A Demo
          </Button>
        </Box>
      </ModalForm>
    </>
  );
}
